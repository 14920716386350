import React from "react";
import styled from "styled-components";
import { Link as LinkScroll } from "react-scroll";

import Button from "../atoms/button";
import H2 from "../atoms/h2";
import { device } from "../utils/media-queries";

const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary.main};
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1030px;
  padding: 80px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary.main};
  color: ${({ theme }) => theme.colors.neutral.black};

  @media ${device.desktop} {
    padding: 80px 48px;
    gap: 32px;
  }

  @media ${device.laptop} {
    padding: 48px 48px;
    gap: 32px;
  }

  @media ${device.mobile} {
    flex-direction: column;
    padding: 32px 20px;
    gap: 32px;
  }
`;

const Title = styled(H2)`
  max-width: 400px;

  @media ${device.tablet} {
    font-size: 42px;
    line-height: 110%;
  }
`;

export const Discuss = ({ scrollToOffset }) => {
  return (
    <PageWrapper>
      <ContentWrapper>
        <Title iswhite>Want to discuss your project?</Title>
        <Button
          as={LinkScroll}
          smooth={true}
          offset={scrollToOffset}
          to="contact"
          isbig="true"
        >
          Contact Us
        </Button>
      </ContentWrapper>
    </PageWrapper>
  );
};
