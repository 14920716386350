import React, { useState } from "react";
import styled from "styled-components";

import Phase from "../molecules/phase";
import H2 from "../atoms/h2";
import { device } from "../utils/media-queries";

const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  padding-bottom: 160px;

  @media ${device.laptop} {
    padding-bottom: 120px;
  }

  @media ${device.tablet} {
    padding-bottom: 80px;
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1030px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${device.laptop} {
    padding: 0 48px;
  }

  @media ${device.mobile} {
    padding: 0 20px;
  }
`;

const ContentWrapperScroll = styled.div`
  width: 100%;
  max-width: 1030px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none;
  }

  @media ${device.laptop} {
    padding: 0 48px;
  }
  @media ${device.mobile} {
    padding: 0 20px;
  }
`;

const Title = styled(H2)`
  margin-bottom: 40px;
  text-align: center;

  @media ${device.mobile} {
    max-width: 220px;
  }

  ::after {
    position: relative;
    top: 16px;
    left: calc(50% - 95px);
    width: 190px;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.additional.green};
    display: block;
    content: "";

    @media ${device.mobile} {
      width: 100px;
      left: calc(50% - 50px);
    }
  }
`;

const Text = styled.div`
  font-size: 16px;
  line-height: 24px;
  max-width: 400px;
  text-align: center;
  color: ${({ theme }) => theme.colors.neutral.secondary};
  margin-bottom: 80px;

  @media ${device.mobile} {
    max-width: 300px;
  }
`;

const BtnsWrapper = styled.div`
  display: flex;
  width: 1030px;
`;

const Btn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  font-size: 16px;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.primary.main};
  background-color: ${({ selected, theme }) =>
    selected ? theme.colors.primary.surface : "transparent"};
  line-height: 30px;
  cursor: pointer;
  width: 295px;
  height: 56px;
  border-radius: 30px;
  font-weight: 700;
  padding: 32px 16px;
  transition: all 0.2s;

  @media ${device.mobile} {
    width: 100%;
  }
`;

const _PHASES = [
  {
    id: 0,
    title: "Project-based (fixed price)",
    alt: "contract image",
    text1:
      "A fully dedicated remote team or person. We have the possibility to delegate full Scrum teams (PM, UI/UX, devs, testers, devops) to work exclusively on your project.",
    text2:
      "Classic model where we take care of the design, implementation and maintenance of agreed features within a specific time frame.",
    img: "Phase1Ill",
  },
  {
    id: 1,
    title: "Team augmentation",
    alt: "globe image",
    text1:
      "A fully dedicated remote team or person. Since the team might be working in places like New York or Kiev, we take care of the logistics. You just pay for labor.",
    text2:
      "A fully dedicated remote team or person. Since the team might be working in places like New York or Kiev, we take care of the logistics. You just pay for labor.",
    img: "Phase2Ill",
  },
  {
    id: 2,
    title: "Consulting  services",
    alt: "notebook image",
    text1:
      "A fully dedicated remote team of experts helping crack the code or review the technical feasibility of your project. You pay only for the experties, we take care of the rest.",
    text2: "Ad hoc concultations. Projects kick-off. Audits.",
    img: "Phase3Ill",
  },
  {
    id: 3,
    title: "Custom deal",
    alt: "puzzles image",
    text1:
      "You have specific requirements or setup? Sit down with us and discuss the best practicies we can apply to help you.",
    text2: "Companies or enterprises with a significant momentum.",
    img: "Phase4Ill",
  },
];

const Workflow = () => {
  const [currentNumber, setCurrentNumber] = useState(0);

  return (
    <PageWrapper>
      <ContentWrapper>
        <Title>How we work with you</Title>
        <Text>
          Our goal is to provide maximum flexibility when cooperating with you
        </Text>
      </ContentWrapper>
      <ContentWrapperScroll>
        <BtnsWrapper>
          <Btn
            onClick={() => setCurrentNumber(0)}
            selected={currentNumber === 0}
          >
            Project-based (fixed price)
          </Btn>
          <Btn
            onClick={() => setCurrentNumber(1)}
            selected={currentNumber === 1}
          >
            Team augmentation
          </Btn>
          <Btn
            onClick={() => setCurrentNumber(2)}
            selected={currentNumber === 2}
          >
            Consulting services
          </Btn>
          <Btn
            onClick={() => setCurrentNumber(3)}
            selected={currentNumber === 3}
          >
            Custom deal
          </Btn>
        </BtnsWrapper>
      </ContentWrapperScroll>
      <ContentWrapper>
        <Phase selectedPhase={_PHASES[currentNumber]} />
      </ContentWrapper>
    </PageWrapper>
  );
};

export default Workflow;
