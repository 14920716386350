import React from "react";
import styled from "styled-components";
import H3 from "../atoms/h3";

import QuoteMark from "../images/quote-mark.svg";
import { device } from "../utils/media-queries";

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 820px;
  display: flex;
  flex-direction: column;
  min-height: 385px;
  position: relative;
  padding: 40px 48px;
  margin-bottom: 48px;

  @media ${device.desktop} {
    min-height: 0;
    margin-bottom: 16px;
  }

  @media ${device.mobile} {
    padding: 40px 20px;
  }
`;

const Text = styled(H3)`
  margin-top: 24px;
  max-width: 715px;
  color: ${({ theme }) => theme.colors.neutral.secondary};
  text-align: left;

  @media ${device.mobile} {
    text-align: left;
    font-weight: 400;
  }
`;

const AuthorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 700;
  align-self: flex-end;
  margin-top: 70px;
  color: ${({ theme }) => theme.colors.neutral.secondary};
  max-width: 185px;
  text-align: left;
`;

const Author = styled.h3`
  font-size: 32px;
`;

const Company = styled.h3`
  font-size: 16px;
  font-weight: 400;
  margin-top: 8px;
`;
const Quote = styled.img`
  width: 56px;
  height: 38px;
  position: absolute;
  left: -45%;
  top: 0px;

  @media ${device.desktop} {
    position: absolute;
    left: -40%;
    top: 0px;
  }

  @media ${device.tablet} {
    position: absolute;
    left: -40vw;
    top: 10px;
  }
`;

const Review = ({ text, author, company }) => {
  return (
    <ContentWrapper>
      <Quote src={QuoteMark} alt="quotes mark icon" />
      <Text>{text}</Text>
      <AuthorWrapper>
        <Author>{author}</Author>
        <Company>{company}</Company>
      </AuthorWrapper>
    </ContentWrapper>
  );
};

export default Review;
