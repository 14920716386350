import styled from "styled-components";

const H1 = styled.h1`
  font-size: 56px;
  font-weight: 800;
  color: ${({ theme }) => theme.colors.neutral.white};
  line-height: 68px;
`;

export default H1;
