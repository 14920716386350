import React from "react";
import styled from "styled-components";
import H4 from "../atoms/h4";
import { device } from "../utils/media-queries";

const Wrapper = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 30px;
  gap: 20px;
  background-color: ${({ theme }) => theme.colors.neutral.surface};
  max-width: 400px;
  min-height: 426px;
  box-shadow: 0px 10px 17px rgba(0, 0, 0, 0.04);

  @media ${device.tablet} {
    max-width: unset;
    min-height: unset;
    justify-content: unset;
    height: min-content;
  }

  @media ${device.mobile} {
    min-height: unset;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;

  @media ${device.mobile} {
    gap: 16px;
  }
`;

const Title = styled(H4)`
  max-width: 98%;

  @media ${device.mobile} {
    max-width: 90%;
    font-size: 24px;
    line-height: 32px;
  }
`;

const Text = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.neutral.secondary};
`;

const Icon = styled.img`
  max-width: 100%;
  height: 100px;
`;

const Attribute = ({ title, altText, text, icon }) => {
  return (
    <Wrapper>
      <Icon src={icon} alt={altText} />
      <TextWrapper>
        <Title>{title}</Title>
        <Text>{text}</Text>
      </TextWrapper>
    </Wrapper>
  );
};

export default Attribute;
