import React from "react";
import styled from "styled-components";

import Attribute from "../molecules/attribute";

import MedalIcon from "../images/medal-icon.svg";
import DocsIcon from "../images/docs-icon.svg";
import RocketIcon from "../images/rocket-icon.svg";
import FormIcon from "../images/form-icon.svg";
import H2 from "../atoms/h2";
import { device } from "../utils/media-queries";

const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.neutral.white};
  color: ${({ theme }) => theme.colors.neutral.black};
  padding: 160px 0 240px 0;

  @media ${device.laptop} {
    padding: 120px 48px;
  }

  @media ${device.mobile} {
    padding: 80px 20px;
  }
`;

const AttributesWrapper = styled.div`
  width: 100%;
  max-width: 820px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Title = styled(H2)`
  margin-bottom: 80px;
  max-width: 500px;

  ::after {
    position: relative;
    top: 16px;
    left: 0;
    width: 100px;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.additional.green};
    display: block;
    content: "";

    @media ${device.mobile} {
      width: 43px;
    }
  }
`;

const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 40px 20px;

  @media ${device.tablet} {
    grid-template-columns: 1fr;
    gap: 32px;
  }

  @media ${device.mobile} {
    grid-template-columns: 1fr;
    gap: 32px;
  }
`;

const Attributes = () => {
  return (
    <PageWrapper id="atributes">
      <AttributesWrapper>
        <Title>What makes us unique</Title>
        <Grid>
          <Attribute
            icon={MedalIcon}
            altText={"medal icon"}
            title={"An robust partner for emerging projects"}
            text={
              "With a deep practial knowledge of buidling startups from scratch, we've learned the hard way what are the challenges involved. This results in both technical excellence but also strong support on the management side."
            }
          />
          <Attribute
            icon={DocsIcon}
            altText={"document icon"}
            title={"Expertise in building high performant teams"}
            text={
              "Remote first, hybrid work friendly. We can help you build your team right, right from the start. Talk to us and see what are your options to get where you want to be."
            }
          />
          <Attribute
            icon={RocketIcon}
            altText={"rocket icon"}
            title={"Creation of innovative solutions"}
            text={
              "In this fast paced environemnt we feel as natural as it gets. We are always on top of the technology landscape experimenting with new things and building using the best rock-solid solutions."
            }
          />
          <Attribute
            icon={FormIcon}
            altText={"form icon"}
            title={"Perfect partner for your business"}
            text={
              "The reputation is our key value. With that in mind, we alway strive for loyality and consistency with our customers. The greatest reward is when there coming back tu us for more."
            }
          />
        </Grid>
      </AttributesWrapper>
    </PageWrapper>
  );
};

export default Attributes;
