import React from "react";
import styled from "styled-components";

import { device } from "../utils/media-queries";
import H4 from "../atoms/h4";
import { Link } from "gatsby";

const ProjectThumbnail = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  position: relative;
  pointer-events: ${({ comingsoon }) => (comingsoon ? "none" : "auto")};

  &:before {
    position: absolute;
    top: 0px;
    left: 60%;
    width: 120px;
    height: 32px;
    color: ${({ theme }) => theme.colors.neutral.white};
    background-color: ${({ theme }) => theme.colors.system.warning};
    display: flex;
    justify-content: center;
    align-items: center;
    content: "Coming Soon";
    border-radius: 0 0 15px 15px;
    font-size: 13px;
    display: ${({ comingsoon }) => (comingsoon ? "flex" : "none")};

    @media ${device.tablet} {
      width: 110px;
      height: 26px;
      left: calc(50% - 55px);
    }
  }
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  background-color: grey;
  border-radius: 30px;
  transition: all 0.3s;

  ${ProjectThumbnail}:hover & {
    transform: scale(1.05);
  }

  @media ${device.mobile} {
    border-radius: 15px;
  }
`;

const Title = styled(H4)`
  margin: 24px 0 16px 0;
  transition: all 0.3s;

  ${ProjectThumbnail}:hover & {
    color: ${({ theme }) => theme.colors.primary.main};
  }

  @media ${device.tablet} {
    font-size: 24px;
    margin: 8px 0 4px 0;
    line-height: 100%;
  }

  @media ${device.mobile} {
    font-size: 16px;
    margin: 8px 0 0 0;
    line-height: 150%;
    overflow-wrap: break-word;
  }
`;

const Description = styled.div`
  color: ${({ theme }) => theme.colors.neutral.secondary};
  font-size: 16px;

  @media ${device.mobile} {
    display: none;
  }
`;

const ProjectsThumbnailSmall = ({
  external,
  title,
  desc,
  iscomingsoon,
  image,
  pageurl,
}) => {
  return (
    <ProjectThumbnail
      as={external ? "a" : Link}
      href={pageurl}
      to={pageurl}
      target={external ? "_blank" : null}
      comingsoon={iscomingsoon}
    >
      <Image src={image} alt={title + " thumbnail"} />
      <Title>{title}</Title>
      <Description>{desc}</Description>
    </ProjectThumbnail>
  );
};

export default ProjectsThumbnailSmall;
