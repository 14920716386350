import React from "react";
import styled from "styled-components";
import Button from "../atoms/button";

import H3 from "../atoms/h3";
import { FakeDescriptiveText } from "../atoms/fake-descriptive-text";

import DefdoneLogo from "../images/defdone-logo.svg";
import { device } from "../utils/media-queries";

const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.neutral.surface};
  padding: 160px 0 80px 0;
  margin-bottom: 160px;

  @media ${device.laptop} {
    padding: 80px 48px 60px 48px;
    margin-bottom: 80px;
  }

  @media ${device.tablet} {
    padding: 80px 48px 40px 48px;
    margin-bottom: 80px;
  }

  @media ${device.mobile} {
    padding: 80px 20px 40px 20px;
    margin-bottom: 80px;
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 820px;
  display: flex;
  justify-content: space-between;
  align-items: left;

  @media ${device.laptop} {
    gap: 40px;
  }
`;

const Text = styled.p`
  color: ${({ theme }) => theme.colors.neutral.secondary};
  margin-top: 16px;
  font-size: 16px;
  line-height: 150%;
`;

const Logo = styled.img`
  width: 293px;
  height: auto;
  border-radius: 30px;

  @media ${device.tablet} {
    width: 50%;
    align-self: flex-start;
    border-radius: 15px;
  }
`;

const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 500px;
`;

const SeeMoreBtn = styled(Button)`
  margin-top: 80px;
`;

const Collaboration = () => {
  return (
    <PageWrapper>
      <ContentWrapper>
        <Column>
          <H3>We work in collaboration with Defdone.com</H3>
          <Text>
            We provide comprehensive product expertise because, in addition to
            our team, we also cooperate closely with Defdone.com.
          </Text>
          <SeeMoreBtn isBlue as="a" href="https://defdone.com/" target="_blank">
            See More
            {/* Added for better scores in Lighthouse */}
            <FakeDescriptiveText>about Defdone</FakeDescriptiveText>
          </SeeMoreBtn>
        </Column>
        <Logo src={DefdoneLogo} alt="defdone.com logo" />
      </ContentWrapper>
    </PageWrapper>
  );
};

export default Collaboration;
