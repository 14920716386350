import React from "react";
import styled from "styled-components";
import H3 from "../atoms/h3";
import { device } from "../utils/media-queries";

const PhaseWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 48px;
  background-color: ${({ theme }) => theme.colors.primary.surface};
  border-radius: 30px;
  width: 100%;
  max-width: 820px;
  margin-top: 80px;
  min-height: 366px;

  @media ${device.laptop} {
    min-height: unset;
    padding: 32px;
    position: relative;
  }

  @media ${device.tablet} {
    padding: 32px;
    position: relative;
  }
`;

const DescriptionWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-content: space-between;
  flex-direction: column;
  width: 100%;
  max-width: 457px;
`;

const PhaseHeader = styled(H3)`
  margin-bottom: 32px;

  @media ${device.laptop} {
    max-width: 350px;
  }

  @media ${device.tablet} {
    max-width: 250px;
  }
`;

const Text = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.neutral.secondary};
`;

const TextRecomended = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  margin: 24px 0 8px 0;
  color: ${({ theme }) => theme.colors.neutral.secondary};
`;

const Illustration = styled.img`
  height: 250px;
  width: auto;
  position: relative;
  align-self: flex-end;
  left: 135px;
  top: 70px;

  @media ${device.laptop} {
    height: 200px;
    top: -30px;
    right: -30px;
    left: unset;
    position: absolute;
  }

  @media ${device.tablet} {
    height: 130px;
    top: -40px;
    right: -10px;
    left: unset;
    position: absolute;
  }

  @media ${device.mobile} {
    height: 110px;
    top: -30px;
    right: -5px;
    left: unset;
    position: absolute;
  }
`;

const Phase = ({ selectedPhase }) => {
  return (
    <PhaseWrapper>
      <DescriptionWrapper>
        <PhaseHeader isBlue>{selectedPhase.title}</PhaseHeader>
        <Text>{selectedPhase.text1}</Text>
        <TextRecomended>Recommended for:</TextRecomended>
        <Text>{selectedPhase.text2}</Text>
      </DescriptionWrapper>
      <Illustration
        src={`/static/phase${selectedPhase.id + 1}-ill.svg`}
        alt={selectedPhase.alt}
      />
    </PhaseWrapper>
  );
};

export default Phase;
