import React from "react";
import styled from "styled-components";

import { device } from "../utils/media-queries";
import ProjectsThumbnailSmall from "../molecules/project-thumbnail-small";
import H2 from "../atoms/h2";
import { CASPERBLOCKCHAIN_LINK, CASPERTIGERS_LINK } from "../config";

import CasperTigersImg from "../images/caspertigers-thumbnail-small.svg";
import CasperblockchainSmallImg from "../images/casperecosystem-banner-small.png";
import FSImg from "../images/fs-thumbnail-small.svg";
import { FakeDescriptiveText } from "../atoms/fake-descriptive-text";
import Button from "../atoms/button";
import { Link } from "gatsby";

const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.neutral.surface};
  padding: 80px;

  @media ${device.desktop} {
    padding: 40px 48px;
  }

  @media ${device.laptop} {
    padding: 40px 48px;
  }

  @media ${device.mobile} {
    padding: 40px 20px;
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1030px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const List = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;

  @media ${device.tablet} {
    gap: 32px;
    flex-direction: column;
  }
`;

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const Title = styled(H2)`
  margin-bottom: 80px;
  align-self: flex-start;

  @media ${device.mobile} {
    margin-bottom: 48px;
  }

  ::after {
    position: relative;
    top: 16px;
    left: 0;
    width: 100px;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.additional.green};
    display: block;
    content: "";

    @media ${device.mobile} {
      width: 43px;
    }
  }
`;

const ButtonMargin = styled(Button)`
  margin-top: 15px;

  @media ${device.tablet} {
    display: none;
  }
`;

const TextButton = styled(Button)`
  display: none;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.primary.main};
  margin-top: 20px;

  @media ${device.tablet} {
    display: flex;
  }
`;

const OurProjects = () => {
  return (
    <PageWrapper>
      <ContentWrapper>
        <TitleWrapper>
          <Title>Our Projects</Title>
          <ButtonMargin as={Link} to={"/projects"} isBlue>
            More
            {/* Added for better scores in Lighthouse */}
            <FakeDescriptiveText>about projects</FakeDescriptiveText>
          </ButtonMargin>
        </TitleWrapper>
        <List>
          <ProjectsThumbnailSmall
            external
            pageurl={CASPERTIGERS_LINK}
            title={"CasperTigers.io"}
            desc={"Unique NFT platform for the Casper Blockchain"}
            image={CasperTigersImg}
          />
          <ProjectsThumbnailSmall
            external
            pageurl={CASPERBLOCKCHAIN_LINK}
            title={"Casperecosystem.io"}
            desc={"Ecosystem explorer for the Casper Blockchain"}
            image={CasperblockchainSmallImg}
          />
          <ProjectsThumbnailSmall
            pageurl={"/projects/friendlysoccer"}
            title={"Friendly Soccer"}
            desc={"The first web3 game on the Casper blockchain"}
            image={FSImg}
          />
        </List>
        <TextButton as={Link} to={"/projects"} isBlue>
          More
          {/* Added for better scores in Lighthouse */}
          <FakeDescriptiveText>about projects</FakeDescriptiveText>
        </TextButton>
      </ContentWrapper>
    </PageWrapper>
  );
};

export default OurProjects;
