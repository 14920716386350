import React from "react";
import styled from "styled-components";

import H2 from "../atoms/h2";
import { device } from "../utils/media-queries";

import PolygonIcon from "../images/polygon-icon.svg";
import CasperIcon from "../images/casper-icon.svg";
import AwsIcon from "../images/aws-icon.svg";
import NearIcon from "../images/near-icon.svg";
import DefdoneIcon from "../images/defdone-logo.svg";
import GithubIcon from "../images/github-icon.svg";
import EggforceLogo from "../images/eggforce-logo.png";

const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 160px 0;
  font-size: 16px;

  @media ${device.desktop} {
    padding: 160px 48px;
  }

  @media ${device.laptop} {
    padding: 80px 48px;
  }

  @media ${device.tablet} {
    padding: 80px 0 80px 20px;
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1030px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.neutral.white};
`;

const Title = styled(H2)`
  margin-bottom: 40px;
  text-align: center;
  max-width: 610px;
  color: ${({ theme }) => theme.colors.neutral.secondary};
  opacity: 0.7;

  @media ${device.tablet} {
    margin-bottom: 80px;
  }

  @media ${device.mobile} {
    margin-bottom: 48px;
    font-size: 18px;
    line-height: 24px;
    max-width: 220px;
    margin-right: 20px;
  }
`;

const Logos = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  max-height: 50px;
  margin-top: 80px;

  @media ${device.laptop} {
    margin-top: 0;
    overflow-x: scroll;
    gap: 32px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

const Logo = styled.img`
  height: auto;
  max-height: 50px;
  max-width: 200px;

  @media ${device.tablet} {
    height: 50px;
    width: auto;
  }
`;

const Partners = () => {
  return (
    <PageWrapper>
      <ContentWrapper>
        <Title>Trusted by global brands and partners</Title>
        <Logos>
          <a
            href="https://polygon.technology/"
            target="_blank"
            rel="noreferrer"
          >
            <Logo src={PolygonIcon} alt="polygon logo" />
          </a>
          <a href="https://casper.network" target="_blank" rel="noreferrer">
            <Logo src={CasperIcon} alt="casper logo" />
          </a>
          <a href="https://aws.amazon.com/" target="_blank" rel="noreferrer">
            <Logo src={AwsIcon} alt="aws logo" />
          </a>
          <a href="https://near.org/" target="_blank" rel="noreferrer">
            <Logo src={NearIcon} alt="near logo" />
          </a>
          <a href="https://defdone.com" target="_blank" rel="noreferrer">
            <Logo src={DefdoneIcon} alt="defdone logo" />
          </a>
          <a href="https://github.com/" target="_blank" rel="noreferrer">
            <Logo src={GithubIcon} alt="github logo" />
          </a>
          <a href="https://www.eggforce.io/" target="_blank" rel="noreferrer">
            <Logo src={EggforceLogo} alt="eggforce logo" />
          </a>
        </Logos>
      </ContentWrapper>
    </PageWrapper>
  );
};

export default Partners;
