import styled from "styled-components";

import { device } from "../utils/media-queries";

const H3 = styled.h3`
  font-size: 32px;
  font-weight: 700;
  color: ${({ theme, isWhite }) =>
    isWhite ? theme.colors.neutral.white : theme.colors.neutral.black};
  color: ${({ theme, isBlue }) => (isBlue ? theme.colors.primary.main : null)};
  line-height: 38px;

  @media ${device.laptop} {
    font-size: 24px;
    line-height: 150%;
  }

  @media ${device.mobile} {
    font-size: 18px;
    line-height: 150%;
  }
`;

export default H3;
