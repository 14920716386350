import React from "react";
import styled from "styled-components";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import TechBlock from "../atoms/tech-block";
import H2 from "../atoms/h2";
import Dot from "../atoms/dot";

import { device } from "../utils/media-queries";

import BlockchainIcon from "../images/blockchain-icon.svg";
import FigmaIcon from "../images/figma-icon.svg";
import JSIcon from "../images/js-icon.svg";
import RustIcon from "../images/rust-icon.svg";
import SmartIcon from "../images/smart-icon.svg";
import PythonIcon from "../images/python-icon.svg";
import DockerIcon from "../images/docker-icon.svg";
import NodeIcon from "../images/node-icon.svg";
import ReactIcon from "../images/react-icon.svg";
import CloudIcon from "../images/cloud-icon.svg";

const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.neutral.white};
  color: ${({ theme }) => theme.colors.neutral.black};
  margin-bottom: 240px;

  @media ${device.desktop} {
    margin-bottom: 80px;
    padding: 0 48px;
  }

  @media ${device.laptop} {
    margin-bottom: 80px;
    padding: 0 48px;
  }

  @media ${device.tablet} {
    margin-bottom: 70px;
    padding: 0 48px;
  }

  @media ${device.mobile} {
    margin-bottom: 70px;
    padding: 0 20px;
  }
`;

const TechnologiesWrapper = styled.div`
  width: 100%;
  max-width: 1030px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Title = styled(H2)`
  margin-bottom: 80px;
  max-width: 500px;

  ::after {
    position: relative;
    top: 16px;
    left: 0;
    width: 100px;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.additional.green};
    display: block;
    content: "";

    @media ${device.mobile} {
      width: 43px;
    }
  }
`;

const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 1fr 1fr;
  gap: 24px;
  padding: 48px 117px;
  background-color: ${({ theme }) => theme.colors.neutral.surface};
  border-radius: 35px;

  @media ${device.desktop} {
    padding: 48px 48px;
    align-items: center;
    justify-items: center;
  }

  @media ${device.laptop} {
    padding: 20px;
    gap: 16px;
  }

  @media ${device.tablet} {
    background-color: transparent;
    padding: 0;
  }
`;

const GridMobile3Columns = styled(Grid)`
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  margin-bottom: 46px;

  @media ${device.mobile} {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
`;

const GridMobile2Columns = styled(Grid)`
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  margin-bottom: 46px;
`;

const Technologies = ({ mobile }) => {
  return (
    <PageWrapper>
      <TechnologiesWrapper>
        <Title>Technologies</Title>

        {mobile ? (
          <Carousel
            showArrows={false}
            showStatus={false}
            showIndicators
            swipeScrollTolerance={100}
            preventMovementUntilSwipeScrollTolerance
            showThumbs={false}
            renderIndicator={(onClickHandler, isSelected, index) => {
              if (isSelected) {
                return <Dot selected />;
              }
              return (
                <Dot
                  onClick={onClickHandler}
                  onKeyDown={onClickHandler}
                  value={index}
                  key={index}
                  role="button"
                  tabIndex={0}
                />
              );
            }}
          >
            <GridMobile3Columns>
              <TechBlock icon={BlockchainIcon} text={"Blockchain"} />
              <TechBlock icon={FigmaIcon} text={"Figma"} />
              <TechBlock icon={JSIcon} text={"JavaScript"} />
              <TechBlock icon={RustIcon} text={"Rust"} />
              <TechBlock icon={SmartIcon} text={"Smart contracts"} />
              <TechBlock icon={PythonIcon} text={"Python"} />
            </GridMobile3Columns>
            <GridMobile2Columns>
              <TechBlock icon={DockerIcon} text={"Docker"} />
              <TechBlock icon={NodeIcon} text={"Node.js"} />
              <TechBlock icon={ReactIcon} text={"React.js"} />
              <TechBlock icon={CloudIcon} text={"Cloude infrastructure"} />
            </GridMobile2Columns>
          </Carousel>
        ) : (
          <Grid>
            <TechBlock icon={BlockchainIcon} text={"Blockchain"} />
            <TechBlock icon={FigmaIcon} text={"Figma"} />
            <TechBlock icon={JSIcon} text={"JavaScript"} />
            <TechBlock icon={RustIcon} text={"Rust"} />
            <TechBlock icon={SmartIcon} text={"Smart contracts"} />
            <TechBlock icon={PythonIcon} text={"Python"} />
            <TechBlock icon={DockerIcon} text={"Docker"} />
            <TechBlock icon={NodeIcon} text={"Node.js"} />
            <TechBlock icon={ReactIcon} text={"React.js"} />
            <TechBlock icon={CloudIcon} text={"Cloude infrastructure"} />
          </Grid>
        )}
      </TechnologiesWrapper>
    </PageWrapper>
  );
};

export default Technologies;
