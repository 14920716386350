import React from "react";
import styled from "styled-components";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import Review from "../molecules/review";
import { device } from "../utils/media-queries";
import Dot from "../atoms/dot";

const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px 0;
  font-size: 16px;
  background-color: ${({ theme }) => theme.colors.neutral.surface};

  @media ${device.tablet} {
    padding: 40px 0 16px 0;
  }
`;
const ContentWrapper = styled.div`
  width: 100%;
  max-width: 820px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Reviews = () => {
  return (
    <PageWrapper>
      <ContentWrapper>
        <Carousel
          showArrows={false}
          showStatus={false}
          showIndicators
          swipeScrollTolerance={100}
          preventMovementUntilSwipeScrollTolerance
          autoPlay
          infiniteLoop
          interval={6000}
          showThumbs={false}
          renderIndicator={(onClickHandler, isSelected, index) => {
            if (isSelected) {
              return <Dot selected aria-label="dot selected" />;
            }
            return (
              <Dot
                onClick={onClickHandler}
                onKeyDown={onClickHandler}
                value={index}
                key={index}
                role="button"
                tabIndex={0}
                aria-label="dot"
              />
            );
          }}
        >
          <Review
            text={
              "Blockbites knows their business. Direct comms, technical excellece and timely delivery"
            }
            author={"Marco Sassi"}
            company={"Enterprenuer, Genoa, Italy."}
          />
          <Review
            text={
              "We are happy to support Blockbites with the best experts on the IT market. Our fruitful collaboration led to the growth of both businesses."
            }
            author={"Bartłomiej Pietrzak"}
            company={"CEO defdone.com, Poznan, Poland"}
          />
        </Carousel>
      </ContentWrapper>
    </PageWrapper>
  );
};

export default Reviews;
