import React from "react";
import styled from "styled-components";
import { device } from "../utils/media-queries";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  border-radius: 15px;
  background-color: ${({ theme }) => theme.colors.neutral.white};
  box-shadow: 0px 10px 17px 0px #0000000a;

  @media ${device.laptop} {
    width: 115px;
    height: 115px;
  }

  @media ${device.mobile} {
    width: 100px;
    height: 100px;
  }
`;

const Icon = styled.img`
  width: 60px !important;
  height: auto;

  @media ${device.laptop} {
    width: 45px !important;
  }

  @media ${device.mobile} {
    width: 40px !important;
  }
`;

const Name = styled.div`
  color: ${({ theme }) => theme.colors.primary.main};
  font-size: 16px;
  margin-top: 16px;
  text-align: center;

  @media ${device.mobile} {
    margin-top: 8px;
    font-size: 13px;
  }
`;

const TechBlock = ({ icon, text }) => {
  return (
    <Wrapper>
      <Icon src={icon} alt={text + " icon"} />
      <Name>{text}</Name>
    </Wrapper>
  );
};

export default TechBlock;
