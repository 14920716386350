import React from "react";

import Attributes from "../organisms/attributes";
import Banner from "../organisms/banner";
import Layout from "../layout/Layout";
import { Discuss } from "../organisms/discuss";
import Introduction from "../organisms/introduction";
import Partners from "../organisms/partners";
import Reviews from "../organisms/reviews";
import Technologies from "../molecules/technologies";
import Topbar from "../molecules/topbar";
import Workflow from "../organisms/workflow";
import Collaboration from "../organisms/collaboration";
import OurProjects from "../organisms/our-projects";
import Seo from "../layout/Seo";

const HomePage = () => {
  return (
    <Layout>
      <Topbar />
      <Banner />
      <Introduction />
      <Attributes />
      <Technologies />
      <OurProjects />
      <Discuss />
      <Collaboration />
      <Workflow />
      <Reviews />
      <Partners />
    </Layout>
  );
};

export default HomePage;

export const Head = () => <Seo title="Blockbit.es" />;
