import React from "react";
import styled from "styled-components";
import { Link as LinkScroll } from "react-scroll";
import Lottie from "lottie-react";

import contactAnimation from "../anim/anim.json";
import Button from "../atoms/button";
import { device } from "../utils/media-queries";
import H1 from "../atoms/h1";

const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(90deg, #2982e2 33.12%, #00ffca 120.72%);
  overflow-x: hidden;
  color: ${({ theme }) => theme.colors.neutral.white};
  overflow: hidden;
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  min-height: 950px;
  display: flex;
  justify-content: space-between;
  align-items: left;
  padding: 200px 0 88px 0;
  overflow-x: "hidden";

  @media ${device.desktop} {
    padding: 160px 100px;
  }

  @media ${device.laptop} {
    padding: 130px 48px 88px 48px;
  }
  @media ${device.tablet} {
    min-height: unset;
  }
  @media ${device.mobile} {
    padding: 130px 20px 88px 20px;
  }
`;

const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  max-width: 400px;
  margin-right: 50px;
  z-index: 2;

  @media ${device.tablet} {
    margin-right: 0;
  }
`;

const Title = styled(H1)`
  font-weight: 800;
  font-size: 56px;
  letter-spacing: -0.01em;
  line-height: 72px;
  align-self: flex-end;
  max-width: 400px;
  width: 100%;
`;

const Text = styled.p`
  font-size: 16px;
  width: 100%;
  line-height: 150%;
  margin: 48px 0 60px 0;

  @media ${device.mobile} {
    max-width: 215px;
    margin: 24px 0 80px 0;
  }
`;

const LottieWrapper = styled.div`
  width: 1600px;

  @media ${device.desktop} {
    width: 100%;
  }
  @media ${device.mobile} {
    width: 0;
  }
`;

const LottieObject = styled.div`
  width: 100%;
  max-width: 1200px;
  position: absolute;
  left: calc(50vw - 1200px);
  top: 60px;

  @media ${device.desktop} {
    width: 1200px;
    left: calc(50vw - 1200px);
  }

  @media ${device.tablet} {
    width: 900px;
    left: -600px;
    top: 50px;
  }

  @media ${device.mobile} {
    width: 800px;
    left: -500px;
    top: 50px;
  }
`;

const ButtonSuperWide = styled(Button)`
  max-width: 400px;
  align-self: flex-end;
`;

const Banner = ({ scrollToOffset }) => {
  return (
    <PageWrapper>
      <ContentWrapper>
        <LottieWrapper>
          <LottieObject>
            <Lottie animationData={contactAnimation} />
          </LottieObject>
        </LottieWrapper>
        <TextWrapper>
          <Title>
            Design.
            <br /> Build.
            <br /> Grow.
          </Title>
          <Text>
            Leverage your business`s growth potential with us, using Blockchain,
            Cloud, and cutting-edge technologies.
          </Text>
          <ButtonSuperWide
            iswide="true"
            as={LinkScroll}
            smooth={true}
            offset={scrollToOffset}
            to="atributes"
          >
            Explore Our Work
          </ButtonSuperWide>
        </TextWrapper>
      </ContentWrapper>
    </PageWrapper>
  );
};

export default Banner;
