import styled from "styled-components";

const Dot = styled.li`
  background: #6e778c;
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.additional.green : theme.colors.neutral.secondary};
  width: 10px;
  height: 10px;
  display: inline-block;
  margin: 0px 3px;
  border-radius: 50%;
  cursor: ${({ selected }) => (selected ? "pointer" : "auto")}; ;
`;

export default Dot;
